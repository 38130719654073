

.descargaArchivo {

  .AnimacionLoading {
    position:absolute;
    width:100%;
    height:100%;
    display: -webkit-flex;
    display:  flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    svg {
      width: 100% !important;
      height: 500px !important;
      //width: 70% !important;
      //margin-top: -80px !important;
      //margin-left: 50px !important;
    }
  }


  .membrete {
    float: left !important;
    width: 35% !important;
  }

  .btnCerrar {
    float: right !important;
    width: 10% !important;
    cursor: pointer !important;
  }
}
